import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import Resume from "../components/Resume"
import Experience from "../components/Experience"

const IndexPage = () => (
  <Layout>
    <SEO title="Ben MacDonald" />
    <Hero />
    <Resume />
    <Experience />
  </Layout>
)

export default IndexPage
