import React from "react"

import styles from "./Experience.module.scss";

export default class Experience extends React.Component {

  render() {
    return <section className={styles.Experience}>
      <h3>Experience</h3>
      <div className={styles.Experience_List}>
        <div className={styles.Experience_Item}>
          <h2>Web Development</h2>
          <p>React, Sass, HTML, CSS, JS, TypeScript</p>
        </div>
        <div className={styles.Experience_Item}>
          <h2>iOS Development</h2>
          <p>Swift, xCode, firebase</p>
        </div>
        <div className={styles.Experience_Item}>
          <h2>Backend Development</h2>
          <p>Python, Django, REST</p>
        </div>
      </div>
    </section>
  }
}
