import React from "react"

import styles from "./Hero.module.scss";

export default class Hero extends React.Component {

  render() {
    return <section className={styles.Hero}>
      <h4>I'm a <mark>software engineer</mark> specializing in full stack web development.</h4>
    </section>
  }
}
