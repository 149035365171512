import React from "react"

import styles from "./Resume.module.scss";

export default class Resume extends React.Component {

  render() {
    return <section className={styles.Resume}>
      <h3>Employment</h3>
      <div className={styles.Resume_Content}>
        <div className={styles.Resume_Timeline}></div>

        <div className={styles.Resume_List}>
          <span className={styles.Resume_Job}>
            <h2><a href="https://perpetua.io" className={styles.Resume_Job_Perpetua}>Perpetua Labs</a></h2>
            <p>Web Developer</p>
          </span>
          <span className={styles.Resume_Job}>
            <h2><a href="https://draper.ai" className={styles.Resume_Job_Draper}>Draper AI</a></h2>
            <p>Backend Developer</p>
          </span>
          <span className={styles.Resume_Job}>
            <h2><a href="https://www.sportstats.ca/" className={styles.Resume_Job_SportsStats}>Sports Stats</a></h2>
            <p>Full Stack Developer</p>
          </span>
        </div>
      </div>
    </section>
  }
}
